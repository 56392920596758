.nav-profile-picture {
  max-height: 100%;

  border-radius: 15% !important;
}

.navbar {
  background-color: rgba(36, 36, 36, 0.8);
}
@supports ((-webkit-backdrop-filter: blur(4px)) or (backdrop-filter: blur(4px))) {
  .navbar {
    background-color: rgba(0, 0, 0, .2);
    -webkit-backdrop-filter: blur(1rem);
    backdrop-filter: blur(1rem);
  }
}
.navbar-brand {
  width: 190px;
}

.navbar .breadcrumb .nav-item.active > .nav-link, .navbar .navbar-nav .nav-item.active > .nav-link,
.navbar .navbar-nav .active .nav-link {
  background-color: rgba(200, 200, 200, 0.1);
}
@media (min-width:768px) {
  .navbar .breadcrumb .nav-item.active > .nav-link, .navbar .navbar-nav .nav-item.active > .nav-link,
  .navbar .navbar-nav .active .nav-link {
    border-bottom: 2px solid #afafaf;
    padding-bottom: 4px !important;
    margin-bottom: 4px !important;
  }
}

.nav-item {
  height: 100%;
  display: flex;
}
.nav-link-button {
  width: 100%;
  height: 100%;
}

.navbar .navbar-nav .nav-link {
  border-bottom: 2px solid transparent;
  border-radius: 0;
  font-weight: 400;
}

.navbar .navbar-nav > a {
  margin-right: .25rem;
}

.navbar-brand-title {
  font-size: 1.5rem;
  transition: font-size .5s ease-in-out;
}

.navbar-icon {
  width: 0;
  overflow: hidden;
  opacity: 0;

  transition: width .5s cubic-bezier(.79,.14,.15,.86),
  opacity .5s cubic-bezier(.79,.14,.15,.86);
}
.top-nav-collapse {
  .navbar-icon {
    width: 100%;
    opacity: 1;
  }
  .navbar-brand-title {
    font-size: 1.25rem;
  }
}
