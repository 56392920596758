@import "../Options";

@import "~mdbreact/dist/scss/core/_colors.scss";

#contact-page {
  min-height: 79vh;

  form {

    .submit-button {
      right: 0;
    }

    margin: 0;
    @media (min-width: $breakpoint-tablet) {
      margin: 0 5%;
    }
    @media (min-width: $breakpoint-big-tablet) {
      margin: 0 10%;

      .submit-button {
        left: 0;
        right: unset !important;
      }
    }
  }

  .send-email-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -12px 0 0 -12px;
  }
  .send-email-success {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -10px 0 0 -10px;
    font-size: 20px;
    color: $success-color-dark;
  }
}

.grecaptcha-badge { visibility: hidden; }