@import "../Options";

.projects {
  .container {
    @media (min-width: $breakpoint-phone) {
      max-width: 540px;
    }
    @media (min-width: $breakpoint-tablet) {
      max-width: 720px;
    }
    @media (min-width: $breakpoint-big-tablet) {
      max-width: 960px;
    }
    @media (min-width: $breakpoint-desktop) {
      max-width: 1440px;
    }
  }
}

.project-card {
  box-shadow: unset !important;
  border-radius: 0 !important;
  background-color: transparent !important;

  .hoverable {
    border-radius: .25rem;
    overflow: hidden;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    transition: all 0.4s ease-in-out;

    &:hover {
      box-shadow: 0 4px 17px 0 rgba(0,0,0,0.24), 0 4px 20px 0 rgba(0,0,0,0.23);
    }
  }
}

.project-image-container {
  width: 100%;
  padding-bottom: 60%; //56.25% is 16:9
  overflow: hidden;
  position: relative;

  .project-image {
    position: absolute;

    // Background size cover replacement
    min-width: 100%;
    min-height: 100%;

    // Background position center replacement
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);

    max-height: 100%;
    max-width: none;
  }
}

.page-content-container {
  .expand-projects-btn {
    i.fa {
      transition: transform 150ms ease-in-out;
    }
  }
  .extra-project, .extra-project-md {
    transition: all .35s ease-in-out;
    overflow: hidden;
    height: 0;
    opacity: 0;
  }
  @media (min-width: $breakpoint-desktop) {
    .extra-project-md {
      transition: unset;
      height: 100% !important;
      opacity: 1 !important;
    }
  }

  &.expanded {
    .expand-projects-btn {
      i.fa {
        transform: rotateX(180deg) translateY(2px);
      }
    }
    .extra-project, .extra-project-md {
      height: 100% !important;
      opacity: 1 !important;
    }
  }
}

.img-transition-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: background-color .3s ease-in-out;
  text-align: center;
  height: 0;
  overflow: hidden;
  background-color: transparent;

  &.active {
    background-color: white;
    height: initial;

    .img-transition {
      animation: project-image-transition .4s ease-in-out forwards;

      box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15);
    }
  }


}

.img-transition {
  position: absolute;

  border-radius: .25rem;
  transition: box-shadow .2s ease-in-out .2s, height 500ms ease-out;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  width: 100%;
  height: 70vh;
  max-height: calc(100vh - 3rem);

  .img-center {
    top: 50%;
    transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    left: 0;
  }
}

.project-page-container {
  min-height: 100vh;

  .project-title div {
    padding-bottom: .7rem;
    margin-bottom: .5rem;

    border-bottom: 4px solid #4285f4;
  }

  .project-video-controls {
    bottom: 1.25rem;
    left: 5%;
    right: 5%;
  }
  .project-title-brand {
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-content: center;
    img {
      margin: auto;
    }
  }


  .bursting-barrel-brand {
    width: 50%;
  }
  .rocket-rampage-brand {
    width: 75%;
  }
  .cook-dont-look-brand {
    max-width: 50%;
    max-height: 60%;
  }
  .paint-brand {
    max-width: 50%;
    max-height: 60%;
    left: 1.5%;
    position: relative;
  }

  .contribution-highlights-list{
    a {
      color: #0050aa;
    }
    li {
      margin-top: .75rem;
      margin-bottom: .75rem;
    }
  }
  .work-item-row {
    padding-left: .75rem;
    padding-right: .75rem;
  }
}

.badge {
  padding: .4rem .6rem;
  cursor: default;
}