@import "../../Portfolio/Options";

.project-page-navbar {
  transition: padding .3s cubic-bezier(0, 0, 0, 1);
  padding-top: 5px;
  padding-bottom: 5px;

  .navbar-brand {
    svg {
      margin-right: 1rem;
      transition: margin-right .35s cubic-bezier(.18,.89,.21,1.47);
      fill: white;
    }
    &:hover {
      svg {
        margin-right: .5rem;
      }
    }
  }
  .project-page-navtitle-container {
    width: 90vw;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    padding: 0;
    position: relative;

    @media (min-width: $breakpoint-big-tablet) {
      padding: 0 1.5rem;
    }

    .project-title-nav {
      top: 3.5rem;
      transition: .3s cubic-bezier(0, 0, 0, 1);
      position: absolute;
      width: 100%;
      text-align: center;
      z-index: -1;

      &.show {
        top: -12px !important;
      }

      &.enlarge-padding {
        padding-top: 7px;
        padding-bottom: 7px;
      }
    }
  }
}



.enlarge-padding {
  padding-top: 12px;
  padding-bottom: 12px;
}