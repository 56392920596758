@import "../Options";

#about-page {
  margin-top: 5vh;
  padding-top: 0 !important;

  border-top: 0 !important;

  .inner-page-content {
    margin-top: 3rem;
    padding-top: 0 !important;
  }

  .profile-picture {
    margin-right: 2rem;
    border-radius: .5rem;
    width: 150px;
    height: auto;
    @media (min-width: $breakpoint-big-tablet) {
      width: 200px;
    }
  }
  .z-index-1 {
    z-index: 1;
  }

  .middle-card {
    margin-top: 2px;
    margin-bottom: 2px;
  }

  .skills-container {

    font-size: 0;
    @media (min-width: $breakpoint-desktop) {
      font-size: .75rem;
    }
    @media (min-width: $breakpoint-big-desktop) {
      font-size: .8rem;
    }
  }

  .skills-icon {
    img, svg {
      width: 100%;
      height: auto;
      transition: filter .25s ease-out;

      &:hover {
        -webkit-filter: grayscale(75%);
        -moz-filter: grayscale(75%);
        -o-filter: grayscale(75%);
        -ms-filter: grayscale(75%);
        filter: grayscale(75%);
      }
    }

    max-width: 85px;
    max-height: 85px;

    @media (min-width: $breakpoint-phone) {
      max-width: 100px;
      max-height: 100px;
    }
    @media (min-width: $breakpoint-tablet) {
      max-width: 125px;
      max-height: 125px;
    }
  }

  .card-group {
    flex-direction: column;
    flex-wrap: nowrap;
    margin: 0 6rem;
    & > .card {
      margin-bottom: 1rem;
      border-radius: .25rem;
    }
    @media (min-width: $breakpoint-big-tablet) {
      flex-direction: row;
      flex-wrap: nowrap;
      margin-bottom: 0;
      & > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
      & > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
}